<script setup lang="ts">
import Button from '~/components/molecules/Button.vue'
import { ButtonType } from '~/src/enums/Button'
import { useUserState } from '~/composables/useUserState'
import { useSignOut } from '~/composables/useSignOut'

interface Props {
  exit?: VoidFunction
}

const props = defineProps<Props>()

const { isClient, isPublisher, toClientDashboard, toPublisherDashboard } = useUserState()
const { signOut } = useSignOut()

const onExitFallback = () => {
  if (isClient.value) toClientDashboard()
  else if (isPublisher.value) toPublisherDashboard()
  else signOut()
}

const onExit = () => {
  (props.exit ? props.exit : onExitFallback)()
}
</script>

<template>
  <Button
    v-analytics:clickExit
    class="!absolute top-10 right-12"
    :type="ButtonType.Link"
    @click="onExit"
  >
    Exit
  </Button>
  <div class="sm:px-12 px-6 py-28 mx-auto w-full m-auto">
    <slot />
  </div>
</template>
